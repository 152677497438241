import React from "react";
import DashboardIcon from "../assets/icons/dashboard.svg";
import CompanyIcon from "../assets/icons/company.svg";
import DepartmentIcon from "../assets/icons/department.svg";
import StaffIcon from "../assets/icons/staff.svg";
import AttendenceIcon from "../assets/icons/attendence.svg";
import ReportIcon from "../assets/icons/report.svg";
import SettingsIcon from "../assets/icons/settings.svg";
import ShiftIcon from "../assets/icons/shift.svg";

import { NavLink, Outlet } from "react-router-dom";

//@ts-ignore
import { Layout } from "@dibtech/styleguide";

const navigation: any = [
  // { name: "Dashboard", href: "/", icon: DashboardIcon },
  // { name: "Company", href: "/companies", icon: CompanyIcon },
  // { name: "Department", href: "/department", icon: DepartmentIcon },
  // { name: "Shift", href: "/shift", icon: ShiftIcon },
  // { name: "Staff", href: "/staffs", icon: StaffIcon },
  { name: "Attendance", href: "/attendance", icon: AttendenceIcon },
  // { name: "Report", href: "/reports", icon: ReportIcon },
  // { name: "Settings", href: "/settings", icon: SettingsIcon },
];

const DashboardLayout = () => {
  return (
    <Layout
      children={<Outlet />}
      navigationComponent={
        <nav className="px-2 space-y-1">
          {navigation?.map((item: any) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive, isPending }) => {
                return `${
                  isActive
                    ? "bg-[#5b6ab0] text-white"
                    : isPending
                    ? "text-indigo-100 hover:bg-indigo-600"
                    : ""
                } group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md`;
              }}
            >
              <img src={item.icon} alt="" className="text-indigo-300" />
              {item.name}
            </NavLink>
          ))}
        </nav>
      }
    />
  );
};

export default DashboardLayout;
