import loadable from "@loadable/component";
import { usePostHook } from "../../customHooks/useApiData";
import { useEffect, useState } from "react";
import moment from "moment";
type Props = {};

const AttendanceComponent = loadable(
  () => import("../../components/Attendance/AttendanceList")
);
const Attendance = (props: Props) => {
  const {
    mutate,
    isLoading: attendanceLoading,
    data: attendanceData,
  } = usePostHook();
  // const { startDate, endDate }: any = useReactiveVar(selectedDatePickerItemVar);
  const [startDate, setStartDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
  );
  const [endDate, setEndDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
  );
  const [dateViewType, setDateViewType] = useState<string>("dayView");
  const [individualDateViewType, setIndividualDateViewType] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [value, setValue] = useState<any>({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  useEffect(() => {
    mutate({
      url: "attendance/",
      formData: {
        start_date: startDate ?? new Date().toISOString(),
        end_date: endDate ?? new Date().toISOString(),
      },
    });
  }, [startDate, endDate]);
  useEffect(() => {
    if (dateViewType === "dayView") {
      setStartDate(
        moment(new Date(value.startDate)).format("YYYY-MM-DD hh:mm:ss")
      );
      setEndDate(
        moment(new Date(value.startDate)).format("YYYY-MM-DD hh:mm:ss")
      );
    } else if (dateViewType === "weekView") {
      const weekDayList = Array.from(Array(8).keys()).map((idx) => {
        const d = new Date(value.startDate);
        d.setDate(d.getDate() - d.getDay() + idx);
        return d;
      });

      setStartDate(moment(weekDayList[1]).format("YYYY-MM-DD hh:mm:ss"));
      setEndDate(moment(weekDayList[7]).format("YYYY-MM-DD hh:mm:ss"));
    } else {
      setStartDate(
        moment(new Date(value.startDate)).format("YYYY-MM-DD hh:mm:ss")
      );
      setEndDate(moment(new Date(value.endDate)).format("YYYY-MM-DD hh:mm:ss"));
    }
  }, [dateViewType, value.startDate, value.endDate]);

  return (
    <div>
      <AttendanceComponent
        data={attendanceData}
        attendanceLoading={attendanceLoading}
        dateViewType={dateViewType}
        setDateViewType={setDateViewType}
        individualDateViewType={individualDateViewType}
        setIndividualDateViewType={setIndividualDateViewType}
        setValue={setValue}
        value={value}
        setStartDate={setStartDate}
      />
    </div>
  );
};

export default Attendance;
